@import url('https://fonts.googleapis.com/css?family=Vollkorn|PT+Sans');


html {
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

body {
    background-color: #222;
    background-image: url('../images/bg_hell.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'PT Sans', sans-serif;
    color: #0D0D0D;
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    font-size: 100%;
}

ul, li {
    padding: 0;
    margin: 0;
    list-style: none;
}

/* ul li {
    padding-left: 1rem;
} */

a {
    color: rgb(0, 137, 216);
    text-decoration: none;
}
a:hover {
    color: rgb(0, 137, 216);
}

h2 {
    padding: 0;
    margin: 0;
    font-family: 'Vollkorn', 'PT Sans', sans-serif;
}
h3 {
    font-family: 'Vollkorn', 'PT Sans', sans-serif;
    margin: 0;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-left: 0;
    padding-right: 0;
}
h4 {
    margin: 0;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0;
    padding-right: 0;
    
}

input,select,textarea,button {
    font-family: 'PT Sans', sans-serif;
}

label {
    font-size: 95%;
    color: rgb(146, 146, 146);
}

input {
    border-width: 2px;
    border-style: solid;
    border-color: rgb(177, 175, 175);
    color: #656565;
    padding: 0.3rem 0.3rem 0.3rem 0.3rem;
    background-color: #fff;
    font-size: 95%;
    display: block;
    width: 15vw;

    -webkit-box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
    box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
}

textarea {
    border-width: 2px;
    border-style: solid;
    border-color: rgb(177, 175, 175);
    color: #656565;
    padding: 0.3rem 0.3rem 0.3rem 0.3rem;
    background-color: #fff;
    font-size: 95%;
    display: block;
    width: 20vw;

    -webkit-box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
    box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
}

input:hover,
select:hover,
.select:hover,
.selectbox-wrap .selectbox:hover {
    border-color: rgb(240, 184, 0) !important;
    opacity: 1 !important;
}

select {
    border-width: 2px;
    border-style: solid;
    border-color: rgb(177, 175, 175);
    color: #656565;
    padding: 0.3rem 0.3rem 0.3rem 0.3rem;
    background-color: #fff;
    font-size: 80%;
    display: block;
    width: 15vw;
    cursor: pointer;

    border-radius: 2rem;

    -webkit-box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
    box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
    
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    background-image: url('../icons/categorie_open.svg');
    background-repeat: no-repeat;
    background-position: 95% center;
    background-size: auto 35%;
}

.select {
    border-width: 2px;
    border-style: solid;
    border-color: rgb(177, 175, 175);
    color: #656565;
    padding: 0.3rem 0.6rem 0.3rem 0.6rem;
    background-color: #fff;
    font-size: 80%;
    display: block;
    width: 15vw;
    cursor: pointer;

    border-radius: 2rem;

    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;

    -webkit-box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
    box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
/* 
    background-color: rgb(243, 243, 243);
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgb(243, 243, 243)), to(#fff));
    background-image: -webkit-linear-gradient(top, rgb(243, 243, 243), #fff);
    background-image: -moz-linear-gradient(top, rgb(243, 243, 243), #fff);
    background-image: -ms-linear-gradient(top, rgb(243, 243, 243), #fff);
    background-image: -o-linear-gradient(top, rgb(243, 243, 243), #fff);
    background-image: linear-gradient(to bottom, rgb(243, 243, 243), #fff);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#E6E6E6, endColorstr=#fff);
  */
}

.selectbox-wrap {
    display: flex;
}
.selectbox-wrap .selectbox {
    border-top: 2px solid rgb(177, 175, 175);
    border-left: 1px solid rgb(177, 175, 175);
    border-bottom: 2px solid rgb(177, 175, 175);
    border-right: 1px solid rgb(177, 175, 175);
    color: #656565;
    padding: 0.3rem 0.6rem 0.3rem 0.6rem;
    background-color: #fff;
    font-size: 80%;
    display: block;
    cursor: pointer;

    border-radius: 0;

    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;

    -webkit-box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
    box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);

}
.selectbox-wrap .selectbox:first-child {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    border-left: 2px solid rgb(177, 175, 175);
}
.selectbox-wrap .selectbox:last-child {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-right: 2px solid rgb(177, 175, 175);
}
.selectbox-wrap .selectbox.active {
    background-color: rgb(92, 92, 92);
    color: #fff;
    border-color: rgb(92, 92, 92);
}


.list-border {
    border: 2px solid rgb(177, 175, 175);
}

.list-wrap {
    height: 7rem;
    overflow: auto;
}

.list-wrap .list-item {
    color: #656565;
    padding: 0.5rem 0.6rem 0.4rem 0.6rem;
    background-color: #fff;
    font-size: 80%;
    display: block;
    cursor: pointer;

    border-radius: 0;

    -webkit-box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
    box-shadow: inset 0px 2px 7px 0px rgba(0,0,0,0.15);
}

.list-wrap .list-item.active {
    background-color: rgb(92, 92, 92);
    color: #fff;
}




table {
    width: 100%;
}

table thead tr th{
   background-color: #fff;
   border-bottom: 1px solid rgb(231, 231, 231);
   text-align: left;
   font-family: 'Vollkorn', 'PT Sans', sans-serif;
}


.btn {
    display: inline-block;
    outline: 0;
    font-family: 'Vollkorn', 'PT Sans', sans-serif;
    font-size: 100%;
    background-color: #ffffff;
    color: rgb(0, 137, 216);
    padding-top: 0.3rem;
    padding-bottom: 0.1rem;
    padding-left: 1rem;
    padding-right: 1.3rem;
    margin: 0;
    cursor: pointer;
    border: 1.5px solid rgb(0, 137, 216);
    border-radius: 0.3rem;
    max-height: 2rem;
}
.btn:hover {
  background-color: rgb(0, 137, 216);
  color: #fff;
}

.btn_editor_page {
    outline: 0;
    border: 0;
    background-color: rgba(0,0,0,0);
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-size: 100%;
}
.btn_editor_edit {
    outline: 0;
    border: 0;
    background-color: rgba(0,0,0,0);
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-size: 100%;

    background-image: url('../icons/edit.svg');
    background-repeat: no-repeat;
    background-size: auto 45%;
    background-position:  center;

    opacity: 0.3;
}
.btn_editor_edit:hover {
    opacity: 1;
}



.btn_editor_sort {
    background-image: url('../icons/sort.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 40%;
    cursor: move;
    opacity: 0.3;
}
.btn_editor_sort:hover {
    opacity: 1;
}

.btn_editor_add {
    outline: 0;
    border: 0;
    background-color: rgba(0,0,0,0);
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-size: 100%;
    /* min-width: 3rem;
    min-height: 1.3rem; */

    background-image: url('../icons/add.svg');
    background-repeat: no-repeat;
    background-size: auto 40%;
    background-position:  center;

    /* overflow: hidden;
    text-align: left;
    padding-left: 1.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-right: 0.5rem; */

    color: rgb(95, 95, 95);
    font-weight: 100;
    letter-spacing: 0.02rem;

    opacity: 0.3;
}
.btn_editor_add:hover {
    opacity: 1;
}


.btn.variableHandler {
    border-color: rgb(240, 0, 140);
    color: rgb(240, 0, 140);
    display: block;
}
.btn.variableHandler:hover {
    background-color: rgb(240, 0, 140);
    color: #fff;
}

.btn_add {
    background-image: url('../icons/add.svg');
    background-repeat: no-repeat;
    background-size: auto 45%;
    background-position: 0.5rem center;
    padding-left: 2rem;
  }

  .btn_save {
    background-image: url('../icons/save.svg');
    background-repeat: no-repeat;
    background-size: auto 45%;
    background-position: 0.5rem center;
    padding-left: 2rem;
  }

  .btn_field {
    background-image: url('../icons/nav-fields.svg');
    background-repeat: no-repeat;
    background-size: auto 45%;
    background-position: 0.5rem center;
    padding-left: 2rem;

  }

  .btn_code {
    background-image: url('../icons/nav-code.svg');
    background-repeat: no-repeat;
    background-size: auto 55%;
    background-position: 0.5rem center;
    padding-left: 2.2rem;
  
  }




#nav {
    height: 7vh;
    background-color: #fff;
    border-bottom: 1px solid rgb(231, 231, 231);
    display: flex;
}

#nav .nav-logo {
    display: block;

    /*background-color: rgb(11, 119, 192);*/
    background-image: url('../images/logo.svg');
    background-repeat: no-repeat;
    background-size: auto 80%;
    background-position: 1rem center;

    flex: 1;
    min-width: 10rem;
    max-width: 10rem;
}

#nav .nav-main {
    display: flex;
    /* height: 100%; */
    flex: 1;
    padding-top: 0.4rem;
    padding-left: 4rem;
}

#nav .nav-main .nav-pages {
    display: flex;
    height: 100%;
    flex: 3;
}
#nav .nav-main .nav-config {
    display: flex;
    height: 100%;
    flex: 1;
    justify-content: flex-end;
}


#nav .nav-main .nav-item {
    display: flex;
    flex: 1;
    max-width: 7rem;
    min-width: 3rem;

    background-repeat: no-repeat;
    background-size: auto 45%;
    background-position: left center;
    
}
#nav .nav-main .nav-item:hover {
    background-position: left 0.5rem;
}

#nav .nav-main .nav-item a {
    flex: 1;
    padding-left: 2.2rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    color: #000;
    padding-bottom: 0.5rem;
    font-size: 80%;
}

.icon-nav-editor {
    background-image: url('../icons/nav-editor.svg');
}

.icon-nav-categories {
    background-image: url('../icons/nav-categories.svg');
}

.icon-nav-pages {
    background-image: url('../icons/nav-pages.svg');
}

.icon-nav-pages_edit {
    background-image: url('../icons/page_edit.svg');
}

.icon-nav-templates {
    background-image: url('../icons/nav-templates.svg');
}

.icon-nav-config {
    background-image: url('../icons/nav-config.svg');
}

.icon-nav-config {
    background-image: url('../icons/nav-config.svg');
}

.icon-nav-user {
    background-image: url('../icons/nav-user.svg');
}

.icon-nav-fields {
    background-image: url('../icons/nav-fields.svg');
}
.icon-nav-code {
    background-image: url('../icons/nav-code.svg');
}
.icon-nav-back {
    background-image: url('../icons/nav-back.svg');
}
.icon-nav-gallery {
    background-image: url('../icons/nav-gallery.svg');
}
.icon-nav-export {
    background-image: url('../icons/nav-export.svg');
}


.error {

    color: #fff;
    font-size: 110%;
    font-weight: bold;
    background-color: rgb(121, 0, 0);
    border-radius: 0.5rem;
    margin-right: 2rem;
    padding: 1rem;
}

#footer {
    height: 3vh;
    background-color: #222;
    color: rgb(131, 131, 131);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 70%;
    
    

}


#main {

    height: 90vh;
    overflow: hidden;
}

#main  .breadcrumb {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-left: 2vw;
    padding-right: 2vw;
    height: 3rem;
    padding-bottom: 1rem;
}

#main  .breadcrumb  a {
    display: flex;
    align-items: center;
    background-color: #fff;
    
    height: 2rem;
    
    border-right: 1px solid #ccc;

    background-repeat: no-repeat;
    background-size: auto 55%;
    background-position: 0.5rem center;

    padding-left: 2.3rem;
    padding-right: 1rem;

}

#main  .breadcrumb  a:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

#main  .breadcrumb  a:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border:0;
}

#main  .breadcrumb  a:hover {
    background-color: rgb(0, 137, 216);
    color: #fff;
}


#main .list-header {
    display: flex;

    padding-left: 2vw;
    padding-right: 2vw;
    height: 3rem;
}

#main .list-header .icon {
    flex: 1;
    min-width: 3rem;
    max-width: 3rem;
    min-height: 3rem;
    max-height: 3rem;

    background-repeat: no-repeat;
    background-size: 65% auto;
    background-position: left center;
}

#main .list-header h2 {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#main #app {
    height: 87vh;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 3vh;
}

#main #app.withHeader {
    height: calc( 87vh - 4rem - 3rem );
    padding-left: 2vw;
    padding-right: 2vw;
    overflow-y:auto;
}


#main .form {
    padding-left: 5vw;
    margin-top: 0.5rem;
    
    /* height: 100%; */
    /* flex:1;
    overflow-y:scroll; */
    height: 65vh;

  }

#main  .form .my-form-class #fields_form {
      padding-top: 2rem;
  }

#main .form .my-form-class #fields_form div {
    padding-top: 0.5rem;
  }





#main #app .table .table-component__filter {
    display: flex;
    justify-content: flex-end;
    padding-right: 5vw;
}
  

#main #app .table .table-component__table-wrapper {
    padding-top: 3vw;
    padding-right: 5vw;
}

#main #app .table .table-component__table__caption {
    display: none;
}


/**

    TOOLTIP
*/
.tooltip {
    display: block !important;
    z-index: 9000000;
  }
  .tooltip .tooltip-inner {
    background: rgb(121, 121, 121);
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: rgb(121, 121, 121);
  }
  .tooltip[x-placement^=top] {
    margin-bottom: 5px;
  }
  .tooltip[x-placement^=top] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }
  .tooltip[x-placement^=bottom] {
    margin-top: 5px;
  }
  .tooltip[x-placement^=bottom] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }
  .tooltip[x-placement^=right] {
    margin-left: 5px;
  }
  .tooltip[x-placement^=right] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }
  .tooltip[x-placement^=left] {
    margin-right: 5px;
  }
  .tooltip[x-placement^=left] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }
  .tooltip[aria-hidden=true] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }
  .tooltip[aria-hidden=false] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }


  .ajaxformWrap {

    position: absolute;
    z-index: 900000;
    top: 15vh;
    left: 30vw;
    right: 0;

    width: 30vw;

    background-color: #fff;

    padding: 1rem;
    padding-bottom: 2rem;
    border: 2px solid #ccc;


    -webkit-box-shadow: 3px 2px 15px -4px rgba(0,0,0,0.4);
    -moz-box-shadow: 3px 2px 15px -4px rgba(0,0,0,0.4);
    box-shadow: 3px 2px 15px -4px rgba(0,0,0,0.4);
  }

  .ajaxformWrap.bigger {
    width: 50vw;
  }


  .ajaxformWrap .form {
      padding: 0;
    padding-top: 1rem;
    width: 22vw;
  }
  
  .ajaxformWrap .loading {
    
    height: 20vh;
    background-image: url('../icons/loading.svg');
    background-repeat: no-repeat;
    background-size: auto 50%;
    background-position: center center;
  }
  .ajaxformWrap .loading * {
    display: none;
  }

  .ajaxformWrap .close {
    background-image: url('../icons/close.svg');
    background-repeat: no-repeat;
    background-size: auto 40%;
    background-position: center center;
    width: 3rem;
    height: 3rem;
    position: absolute;
    left: 30vw;
    left: calc( 30vw + 0.7rem );
    top: -1.5rem;
    border: 2px solid rgba(0, 137, 216, 0.3);
    background-color: #fff;
    outline: none;
    border-radius: 3rem;
    cursor: pointer;
  }
  .ajaxformWrap .close:hover {
    border: 2px solid rgb(0, 137, 216);
  }
  .ajaxformWrap.bigger .close {
    left: calc( 50vw + 0.7rem );
  }

  .ajaxformWrap .save {
    background-image: url('../icons/save.svg');
    background-repeat: no-repeat;
    background-size: 40% 40%;
    background-position: center center;
    width: 3rem;
    height: 3rem;
    position: absolute;
    left: 30vw;
    left: calc( 30vw + 0.7rem );
    top: 2rem;
    border: 2px solid rgba(0, 137, 216, 0.3);
    background-color: #fff;
    outline: none;
    border-radius: 3rem;
    cursor: pointer;
    /* font-size: 120%;
    font-weight: bold;
    padding-left: 2.8rem; */

  }

  .ajaxformWrap .save:hover {
    border: 2px solid rgb(0, 137, 216);
  }

  .ajaxformWrap li {
      padding-top: 1rem;
  }


  .ajaxformWrap .bigger {
      margin-bottom: 1rem;
  }
  .ajaxformWrap .bigger label {
      font-size: 110%;
  }
  .ajaxformWrap .bigger input {
    font-size: 110%;
    padding: 0.5rem;
    /* font-weight: bold; */
}

.ajaxformWrap .header {
    background-repeat: no-repeat;
    background-size: auto 60%;
    background-position: left center;
    height: 5vh;
    display: flex;

}

.ajaxformWrap .header h3 {
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-left: 2.5rem;
    padding-bottom: 0;
}


.table-component input {
    width: 30%;
}


.pageChanged {

    margin-left:0.5rem;
  
    width: 1.2rem;
    height: 1.2rem;
    display: inline-block;
  
  
    background-image: url('../icons/cloud_up.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    
  }


  .block-lr {
      display: flex;
      height: 100%;
  }

  .block-lr .left {
      flex: 1;
      height: 100%;
  }
  .block-lr .right {
      flex: 1;
      height: 100%;
  }
  .block-lr .right-3 {
    flex: 3;
}




  .vue-codemirror {
      border: 2px solid #ccc;
      /* margin-top: 1rem;
      margin-right: 1rem; */
  }





  .scrollable {
      overflow-y: scroll;
  }




  .field-item  ul{
    border-left: 1px solid #ccc;
    padding-left:0.6rem;
  }

  .field-item ul li {
     display: flex; /*  OFF: editor -> loop  */
    padding-left: 0.3rem;
    padding-right: 0rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .field-item ul label {
    flex: 1;
  }
  .field-item ul input {
    flex: 1;
  }

  .field-item h4 {
    font-weight: 100;
    /* font-style: italic; */
    font-size: 85%;
    letter-spacing: 0.02rem;

    padding:0;
    margin: 0;
    display: flex;
    color: #ccc;
  }
   .field-item h4 .corner {
     display:inline-block;
     width: 3rem;
     border: 0;
     border-top: 1px solid #ccc;
     border-left: 1px solid #ccc;
     border-top-left-radius: 1rem;
     padding:0;
     margin: 0;
     margin-top:0.6rem;
     margin-right:0.3rem;
   }

   .field-item-loop ul li {
       display: block;
   }


   .table table { 
        border-spacing: 0;
        border-collapse: separate;

        height: 75%;
    }

   .table thead th {
       padding-top:0.4rem;
       padding-left:0.4rem;
   }
   .table table td {
        vertical-align: top;
        padding-top:0.4rem;
        padding-bottom:0.3rem;
        padding-left:0.3rem;
        padding-right:0.3rem;
    }
    .table table tr:nth-Child(odd) {
        background-color: #fff;
    }





.li-vert .select {
    width: auto;
}


